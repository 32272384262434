import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

document.addEventListener("DOMContentLoaded", function(){
  window.addEventListener('scroll', function() {
    if (window.scrollY > 130) {
      if (document.getElementById('navbar_top')) {
        document.getElementById('navbar_top').classList.add('fixed-top');
        document.getElementById('navbar_top').classList.add('white-bg');
      }
    } else {
      if (document.getElementById('navbar_top')) {
        document.getElementById('navbar_top').classList.remove('fixed-top');
        document.getElementById('navbar_top').classList.remove('white-bg');
      }
    }
  });
});

Rails.start()
//Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")
